import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services';

@Injectable()
export class AuthGuard {
  constructor(public auth: AuthService, public router: Router) { }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigateByUrl('/auth/log-in');
      return false;
    }
    return true;
  }
}
