import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@guards';

import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'leads',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'leads',
        loadChildren: () =>
          import('@modules/leads/leads.module').then((m) => m.LeadsModule),
        data: {
          breadcrumb: 'Leads',
        },
      },
      {
        path: 'users',
        loadChildren: () =>
          import('@modules/user/user.module').then((m) => m.UserModule),
        data: {
          breadcrumb: 'Usuarios',
        },
      },
      {
        path: 'stores',
        loadChildren: () =>
          import('@modules/stores/stores.module').then((m) => m.StoresModule),
        data: {
          breadcrumb: 'Tiendas',
        },
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('@modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '**',
    redirectTo: 'leads',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
