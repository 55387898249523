import { environment } from '@env';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const extModules = [
  StoreDevtoolsModule.instrument({
    maxAge: 25,
    logOnly: environment.production,
    autoPause: true,
    features: {
      pause: false, // start/pause recording of dispatched actions
      lock: true, // lock/unlock dispatching actions and side effects
      persist: true, // persist states on page reloading
    },
  }),
];
