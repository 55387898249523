import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AuthGuard, throwIfAlreadyLoaded } from '@guards';

@NgModule({
  imports: [],
  providers: [AuthGuard, provideHttpClient(withInterceptorsFromDi())],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
