import { enableProdMode, LOCALE_ID, DEFAULT_CURRENCY_CODE, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { extModules } from './app/build-specifics';
import { AuthEffects } from '@auth/store/effects/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { JwtModule } from '@auth0/angular-jwt';
import { CoreModule } from '@app/core.module';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { getSpanishPaginatorIntl } from './app/shared/spanish-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { TokenInterceptor, ErrorInterceptor } from '@app/interceptors/token.interceptor';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthService } from './app/shared/services';
import * as fromApp from './app/app.reducer';
import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common';
import localeEsAR from '@angular/common/locales/es-AR';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

export function jwtTokenGetter() {
  const auth = JSON.parse(sessionStorage.getItem('auth'));
  return auth?.user?.token;
}

const interceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
  },
];
const jwtModule = JwtModule.forRoot({
  config: {
    tokenGetter: jwtTokenGetter,
  },
});

registerLocaleData(localeEsAR, 'es-Ar')

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  bootstrapApplication(AppComponent, {
    providers: [
      provideHotToastConfig(),
      importProvidersFrom(BrowserModule, AppRoutingModule, FormsModule, ReactiveFormsModule, RouterModule, MatButtonModule, CoreModule, jwtModule, MatDialogModule, StoreModule.forRoot(fromApp.reducers, {
        metaReducers: fromApp.metaReducers,
      }), EffectsModule.forRoot([AuthEffects]), extModules, NgxMaskDirective, NgxMaskPipe),
      AuthService,
      interceptors,
      provideNgxMask({
        validation: false,
      }),
      {
        provide: MatPaginatorIntl,
        useValue: getSpanishPaginatorIntl(),
      },
      {
        provide: DEFAULT_CURRENCY_CODE,
        useValue: 'ARS',
      },
      {
        provide: LOCALE_ID,
        useValue: 'es-Ar'
      },
      {
        provide: DATE_PIPE_DEFAULT_OPTIONS,
        useValue: {
          dateFormat: 'dd/MM/yy hh:mm',
          timezone: '+0000'
        }
      },
      {
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: {
          appearance: 'outline',
          hideRequiredMarker: true
        }
      },
      {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
          autoFocus: 'dialog'
        }
      },
      {
        provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
        useValue: {
          position: 'above'
        }
      },
      provideHttpClient(withInterceptorsFromDi()),
      provideAnimations(),
    ]
  })
    .catch((err) => console.error(err));
});
