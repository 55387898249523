<nav class="navbar fixed-top navbar-expand-lg navbar-light header-white d-flex justify-content-between">
  <a class="navbar-brand ms-2" routerLink="stores">
    Pedisy
  </a>
  <div id="navBar" class="d-flex align-items-center">
    <p class="d-none d-md-inline-block m-0 me-2">
      {{ authService.user.fullName }}
    </p>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User menu" class="me-2">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" class="nav-menu mt-3">
      <button mat-menu-item class="d-flex justify-content-between align-items-center" (click)="logout()">
        <span class="me-2">Cerrar sesión</span>
        <mat-icon color="warn">exit_to_app</mat-icon>
      </button>
    </mat-menu>
  </div>
</nav>