import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  ActivateLoadingAction,
  DesctivateLoadingAction,
} from '@shared/ui.actions';
import { AppState } from 'app/app.reducer';
import { NavComponent } from '../nav/nav.component';
import { MainNavComponent } from '../main-nav/main-nav.component';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  standalone: true,
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  imports: [NavComponent, MainNavComponent, MatProgressBar]
})
export class ContentLayoutComponent implements OnInit, OnDestroy {
  loadingRouteConfig: boolean;
  subscription: Subscription;
  year: number;

  constructor(private router: Router, private store: Store<AppState>) {
    this.year = new Date().getFullYear();
  }

  ngOnInit() {
    this.subscription = this.store
      .select('ui')
      .subscribe((ui) => (this.loadingRouteConfig = ui.isLoading));

    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.store.dispatch(new ActivateLoadingAction());
      } else if (event instanceof RouteConfigLoadEnd) {
        this.store.dispatch(new DesctivateLoadingAction());
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
