import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss'],
  imports: [RouterOutlet, RouterLink, MatSidenavModule, MatListModule, MatButtonModule, MatIcon, AsyncPipe, NgClass, NgStyle]
})
export class MainNavComponent {
  get opened(): boolean {
    return true;
  }

  navItems: any[];

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>
  ) {
    this.setMenuItems();
  }

  onClickItem(drawer: any): void {
    if (window.innerWidth <= 768) {
      drawer.toggle();
    }
  }

  trackByFn(item: any) {
    return item.link;
  }

  setMenuItems(): void {
    this.store.select('auth').subscribe(() => {
      this.navItems = [
        {
          link: '/leads',
          title: 'Leads',
          icon: 'groups',
        },
        {
          link: '/users',
          title: 'Usuarios',
          icon: 'people',
        },
        {
          link: '/stores',
          title: 'Tiendas',
          icon: 'store',
        },
      ];
    });
  }
}
