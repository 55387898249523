import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from '@services';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LogOut } from '@auth/store/actions/auth.actions';
import { AppState } from 'app/app.reducer';
import Swal from 'sweetalert2';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: AuthService;

  constructor(private injector: Injector) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    const setHeaders = {};
    const auth: string = this.authService.getToken();
    const storeId: string = this.authService.user.mainStore?.id;

    if (auth) {
      setHeaders['auth'] = auth;
    }

    if (storeId) {
      setHeaders['store'] = storeId;
    }

    request = request.clone({ setHeaders });

    return next.handle(request);
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse && response.status === 401) {
          this.store.dispatch(new LogOut());
          Swal.fire({
            title: 'Sesión expirada',
            html: 'Por seguridad, tu sesión ha sido expirada. <br>Por favor volvé a iniciar sesión.',
            confirmButtonColor: '#000',
            confirmButtonText: 'Aceptar',
          });
        }

        if (
          response.status === 400 &&
          response?.error?.message === 'Token is expired'
        ) {
          this.store.dispatch(new LogOut());
          Swal.fire({
            title: 'Sesión expirada',
            text: 'Por seguridad, tu sesión ha sido expirada. Por favor volvé a iniciar sesión.',
            confirmButtonColor: '#000',
            confirmButtonText: 'Aceptar',
          });
        }

        return throwError(response);
      })
    );
  }
}
