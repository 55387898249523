<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="true">
    <mat-nav-list>
      @for (item of navItems; track item.link) {
      <a mat-list-item [routerLink]="[item.link]" routerLinkActive="active" (click)="onClickItem(drawer)">
        <div class="link-content">
          <mat-icon class="me-2">{{ item.icon }}</mat-icon> {{ item.title }}
        </div>
      </a>
      }
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="position-fixed ps-2" id="breadcrumbToolbar" [ngStyle]="{ 'z-index': 9 }">
      @if (isHandset$ | async) {
      <button mat-icon-button type="button" class="ms-2" aria-label="Toggle sidenav" (click)="drawer.toggle()">
        <mat-icon class="header-mat-icon" aria-label="Side nav toggle icon">
          menu
        </mat-icon>
      </button>
      }
      <div>
        <div class="flex-grow-1 me-2">
          @if (
          (isHandset$ | async) === false ||
          (isHandset$ | async) === null ||
          (isHandset$ | async) === undefined
          ) {
          <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
            <mat-icon class="header-mat-icon" aria-label="Side nav toggle icon">
              menu
            </mat-icon>
          </button>
          }
        </div>
      </div>
    </mat-toolbar>
    <div class="container-fluid container-router-outlet mt-2 mt-md-5">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>