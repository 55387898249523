import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { LogOut } from '@auth/store/actions/auth.actions';
import { AuthService } from '@services';
import { AppState } from 'app/app.reducer';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  standalone: true,
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  imports: [MatButtonModule, MatIcon, MatMenuModule]
})
export class NavComponent {
  subscription: Subscription;
  isCollapsed: boolean;

  constructor(public authService: AuthService, private store: Store<AppState>) {
    this.isCollapsed = true;
  }

  logout(): void {
    this.store.dispatch(new LogOut());
  }
}
