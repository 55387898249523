import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@services';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  AuthActionTypes,
  LogIn,
  LogInFailure,
  LogInSuccess,
} from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router
  ) { }

  LogIn$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.LOGIN),
      map((action: LogIn) => action.payload),
      switchMap((payload) => {
        return this.authService.logIn(payload).pipe(
          map((response: any) => {
            return new LogInSuccess({
              id: response?.data?.id,
              username: response?.data?.username,
              firstName: response?.data?.firstName,
              lastName: response?.data?.lastName,
              phone: response?.data?.phone,
              dni: response?.data?.dni,
              token: response?.data?.token,
              stores: response?.data?.stores || [],
            });
          }),
          catchError((error: any) => {
            return of(new LogInFailure({ error }));
          })
        );
      })
    )
  );

  LogInSuccess$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN_SUCCESS),
        tap(() => this.router.navigateByUrl('/leads'))
      ),
    { dispatch: false }
  );

  LogInFailure$: Observable<any> = createEffect(
    () => this.actions$.pipe(ofType(AuthActionTypes.LOGIN_FAILURE)),
    { dispatch: false }
  );

  LogOut$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActionTypes.LOGOUT),
        tap(() => this.router.navigateByUrl('/auth/log-in'))
      ),
    { dispatch: false }
  );
}
