import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, RouterOutlet } from '@angular/router';
import { filter, map } from 'rxjs/operators';
@Component({
  standalone: true,
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private titleService: Title
  ) { }

  ngOnInit() {
    const appTitle = this.titleService.getTitle();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          window.scrollTo(0, 0);
          return appTitle;
        })
      )
      .subscribe((title: string) =>
        this.titleService.setTitle('Pedisy | ' + title)
      );
  }
}
